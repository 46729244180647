import "./node_modules/bootstrap/dist/css/bootstrap.css";
import { landingViewed, tosViewed, privacyPolicyViewed } from './src/components/Amplitude/events'

export const onRouteUpdate = ({ location }) => {
  const pathname = location.pathname.replace(/\/$/, '');
  
  switch (pathname) {
    case '':
      landingViewed();  
      break;
    case '/terms':
      tosViewed();  
      break;
    case '/policy':
      privacyPolicyViewed();  
      break;
    default:
  }
}
 