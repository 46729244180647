import amplitude from 'amplitude-js'

const API_KEY = process.env.GATSBY_AMPLITUDE_KEY;

export const UPPER_BUTTON = 'Upper';
export const LOWER_BUTTON = 'Lower';

export const joinWaitListClicked = (button, email) => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Join Waitlist Clicked', {
    Email: email,
    Button: button
  });
};

export const lwLinkClicked = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('LambdaWorks Link Clicked');
};

export const landingViewed = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Landing');
};

export const tosViewed = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Terms Of Service');
};

export const privacyPolicyViewed = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude.getInstance().logEvent('Privacy Policy');
};
